
import React from "react";

// const Login = React.lazy(() => import("../../Components/Login/Login.jsx"));
const Menus = React.lazy(() => import("../../Components/Home/Menus.jsx"));

const routes = [
  // {
  //   path: "/",
  //   exact: true,
  //   component: Login,
  // },
  {
    path: "/",
    exact: true,
    component: Menus,
  }
];
export default routes;