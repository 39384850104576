import React, { memo } from "react";
import { renderRoutes } from "react-router-config";
import { HashRouter } from "react-router-dom";
import routes from "./BaseLayer/Router/RouterConfig.js";	//配置的路由
import { Suspense } from 'react';



const App = memo(() => {
  
  return (
    <HashRouter>
       <Suspense fallback={<div>Loading...</div>}> {/* 添加 Suspense 和 fallback */}
        <div>
          {/* 使用 renderRoutes 来渲染路由配置 */}
          {renderRoutes(routes)}
        </div>
      </Suspense>
    </HashRouter>
  )
});
export default App;
